<template>
  <div id="wrapper" class="flex flex-col justify-between h-screen">
    <router-view/>
  </div>
</template>

<style>
input, input:-webkit-autofill, .bootstrap-select.btn-group button{
  background-color: #f3f4f6  !important;
  height: 44px  !important;
  box-shadow: none  !important;
}
</style>

<script>

export default {
  name: 'LayoutNone',
  props: {
    user: Object
  }
}
</script>
